<template>
    <div>
        <v-toolbar elevation="1">
            <v-toolbar-title>Peer review: {{ peerReview.firstName }} &nbsp; {{ peerReview.lastName }}</v-toolbar-title>
        </v-toolbar>
        <v-card v-for="q in peerReview.questions" :key="q.questionId" class="my-8 mx-auto" max-width="900" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <span class="text-subtitle-1">{{ q.position }}. {{ q.title ? q.title : '' }}</span>
                    </v-col>
                </v-row>
                <v-row justify="space-around">
                    <v-col cols="12" lg="6">{{ q.question }}
                    </v-col>
                    <v-col cols="12" lg="5">
                        <p> Mate van ontwikkeling </p>
                        <v-radio-group v-model="q.latency" row disabled>
                            <v-radio v-for="item in radioItems" :key="item.value" :value="item.value"
                                :label="item.text"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row justify="space-around" v-if="q.context">
                    <v-col cols="12" lg="6">
                        <v-textarea disabled outlined v-model="q.context" />
                    </v-col>
                    <v-col cols="5" />
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import { db } from "@/services/firebase";
import { collection, doc, getDoc } from "firebase/firestore";
export default {
    name: "PeerReviewInternal",
    data() {
        return {
            surveyId: "",
            peerId: "",
            peerReview: {},
            headers: [
                {
                    text: "position",
                    value: "position"
                },
                {
                    text: "question",
                    value: "question"
                },
                {
                    text: "latency",
                    value: "latency"
                }
            ],
            radioItems: [
                { text: "Laag", value: 1 },
                { text: "Medium", value: 2 },
                { text: "Hoog", value: 3 },
            ]
        }
    },
    created() {

        // watch the params of the route to fetch the data again
        this.$watch(
            () => this.$route.params,
            (_new, _old) => {
                if (
                    this.$route.name === "PeerReviewInternal"
                ) {
                    this.surveyId = _new.surveyId
                    this.peerId = _new.peerId
                    this.fetchPeerReview().then(() => { });
                }
            },
            // fetch the data when the view is created and the data is
            // already being observed
            { immediate: true }
        );

    },
    methods: {
        async fetchPeerReview() {
            const cRef = doc(db, "surveys", this.surveyId, "peers", this.peerId);
            const res = await getDoc(cRef);
            this.peerReview = res.data()
        }
    }

}
</script>